export enum BankIdAuthType {
  file = 'file',
  qr = 'qr',
}

export enum BankIdOperationStatus {
  completed = 'completed',
  failed = 'failed',
  pending = 'pending',
}

export enum Employment {
  permanent = 'EmploymentPermanent',
  permanent_temp_laid_off = 'EmploymentPermanentTempLaidOff',
  substitute_or_project = 'EmploymentSubstituteOrProject',
  self_employed = 'EmploymentSelfEmployed',
  student = 'EmploymentStudent',
  pensioner = 'EmploymentPensioner',
  sick_pensioner = 'EmploymentSickPensioner',
  sick_leave = 'EmploymentSickLeave',
  unemployed = 'EmploymentUnemployed',
}

export enum MaritalStatus {
  married = 'MaritalStatusMarried',
  cohabiting = 'MaritalStatusCohabiting',
  single = 'MaritalStatusSingle',
}

export enum HomeOwnership {
  cooperative_apartement = 'HomeOwnershipCooperativeApartment',
  house = 'HomeOwnershipHouse',
  rental_apartment = 'HomeOwnershipRentalApartment',
  with_parents = 'HomeOwnershipWithParents',
  sublet = 'HomeOwnershipSublet',
}

export enum IncomeSource {
  swedish_employer = 'IncomeSourceSwedishEmployer',
  foreign_employer = 'IncomeSourceForeignEmployer',
  contributes = 'IncomeSourceContributes',
  pension = 'IncomeSourcePension',
  family_and_relatives = 'IncomeSourceFamilyAndRelatives',
}

export enum LoanReason {
  private_consumption = 'LoanReasonPrivateConsumption',
  bridge_between_salaries = 'LoanReasonBridgeBetweenSalaries',
  investment = 'LoanReasonInvestment',
  family_and_relatives = 'LoanReasonFamilyAndRelatives',
  other = 'LoanReasonOther',
}

export enum LoanWithdrawFrequency {
  multiple_times_per_month = 'LoanWithdrawFrequencyMultipleTimesPerMonth',
  once_a_month = 'LoanWithdrawFrequencyOnceAMonth',
  whole_limit_at_once = 'LoanWithdrawFrequencyWholeLimitAtOnce',
  other = 'LoanWithdrawFrequencyOther',
}

export enum LoanPayType {
  minimum_installment = 'LoanPayTypeMinimumInstallment',
  fast_payment = 'LoanPayTypeFastPayment',
  early_exit = 'LoanPayTypeEarlyExit',
  other = 'LoanPayTypeOther',
}

export enum LoginTypes {
  bankid = 'bankid',
  email = 'email',
  ssn = 'ssn',
}

export enum AccountLetterPaymentStatuses {
  NotPaidInTime = 'NotPaidInTime',
  NotYetDue = 'NotYetDue',
  PaidInTime = 'PaidInTime',
}

export enum AccountLetterTypes {
  Cancellation = 'Cancellation',
  Notice = 'Notice',
  Reminder = 'Reminder',
}

export enum WhiteLabels {
  expresskredit = 'expresskredit',
  gfmoney = 'gfmoney',
  kundfinans = 'kundfinans',
  smspengar = 'smspengar',
}

export enum CreditLimitUpgradeStatusValues {
  approved = 'approved',
  declined = 'declined',
  notFound = 'not-found',
  pending = 'pending',
}
